import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import css from './ServicesDropDown.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import Slider from "react-slick";



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <svg fill="#000000" width="30px" height="30px" viewBox="0 0 1.95 1.95" enableBackground="new 0 0 52 52"><path d="M0.525 1.639V0.311c0 -0.037 0.049 -0.064 0.083 -0.034l0.795 0.649c0.03 0.022 0.03 0.071 0 0.094L0.607 1.676c-0.034 0.026 -0.083 0.004 -0.083 -0.037" /></svg>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <svg fill="#000000" width="30px" height="30px" viewBox="0 0 1.95 1.95" enableBackground="new 0 0 52 52" ><path d="M1.425 0.311v1.327c0 0.037 -0.049 0.064 -0.083 0.034L0.547 1.024c-0.03 -0.022 -0.03 -0.071 0 -0.094L1.342 0.274c0.034 -0.026 0.083 -0.004 0.083 0.037" /></svg>
      </div>
    );
  }
  

const ServicesDropDown = ({ requiredDataFormat, queryParams, intl }) => {
    const ServiceHeading = intl.formatMessage({ id: 'TopbarDesktop.LinksMenu.Services' });

    const [isOpen, setIsOpen] = useState(null); // Track which category dropdown is open
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current index of the slider
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 }); // Track dropdown position
    const sliderContainerRef = useRef(null); // Ref for the slider container
    const dropdownRef = useRef(null);
    const history = useHistory();
    const routeConfiguration = useRouteConfiguration();

    // Function to handle option selection
    const handleSelect = (option, category) => {
        setIsOpen(null); // Close dropdown after selection
        if (queryParams) {
            queryParams(option, category); // Call the provided queryParams function with the selected option
        }
    };

    // Function to handle query parameters
    function queryParams(option, category) {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, { [`pub_${category}`]: option }));
    }

    // Function to toggle dropdown visibility for a specific category
    // Function to toggle dropdown visibility for a specific category
    // Function to toggle dropdown visibility for a specific category
    const toggleDropdown = (index, event) => {
        const pillElement = event.currentTarget; // Get the clicked pill element
        const pillRect = pillElement.getBoundingClientRect(); // Get the pill's position relative to the viewport
        const dropdownWidth = 550; // The width of the dropdown (adjust based on your CSS)
        const pillWidth = pillRect.width; // Width of the pill element
        const screenPadding = 16; // Padding from the edge of the screen
        let leftPosition = pillRect.left + pillWidth / 2 - dropdownWidth / 2; // Center the dropdown relative to the pill

        // Check if the dropdown would go outside the screen on the right
        if (leftPosition + dropdownWidth > window.innerWidth - screenPadding) {
            // Adjust the left position to keep the dropdown inside the viewport
            leftPosition = window.innerWidth - dropdownWidth - screenPadding;
        }

        // Ensure the dropdown doesn't go outside on the left side
        if (leftPosition < screenPadding) {
            leftPosition = screenPadding; // Add padding from the left side of the screen
        }

        setDropdownPosition({
            top: pillRect.bottom + 5, // Set dropdown below the pill
            left: leftPosition,   // Adjusted left value to keep dropdown centered and inside the viewport
        });

        setIsOpen(isOpen === index ? null : index); // Open or close the dropdown for the clicked pill
    };



    // Function to shift the slider left
    const shiftLeft = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1); // Move to the previous item
        }
    };

    // Function to shift the slider right
    const shiftRight = () => {
        if (currentIndex < requiredDataFormat.length - 1) {
            setCurrentIndex(currentIndex + 1); // Move to the next item
        }
    };

    // Calculate the position of each pill dynamically based on the currentIndex
    const getPositionStyle = (index) => {
        const offset = (index - currentIndex) * 150; // Adjust the 150px based on pill width
        return {
            left: `${offset}px`,
        };
    };

    // Close dropdown if clicked outside or scrolled
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(null); // Close dropdown if clicked outside
            }
        };

        const handleScroll = () => {
            if (isOpen !== null) {
                setIsOpen(null); // Close dropdown if the user scrolls the page
            }
        };

        // Add event listeners
        document.addEventListener('mousedown', handleOutsideClick);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <div className={css.dropdowncontainer}>
            <div className={css.sliderControls}>
                {/* <button className={css.scrollButton} onClick={shiftLeft} disabled={currentIndex === 0}>
                    &#8592;
                </button> */}
                <div className={css.sliderContainer} ref={sliderContainerRef}>
                    <Slider {...settings}>

                        {requiredDataFormat.map((category, index) => (
                            <div
                                key={index}
                                className={css.pillWrapper}
                                style={getPositionStyle(index)}
                                id={`pill-${index}`} // Add an ID to each pill for reference
                            >
                                <div
                                    className={css.pill}
                                    onClick={(e) => toggleDropdown(index, e)}
                                >
                                    {category.mainCategory?.label}&nbsp;
                                    <span><svg width="20px" height="20px" viewBox="0 0 1.2 1.2" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h1.2v1.2H0z" fill="none" /><g id="Shopicon"><g><path points="24,29.171 9.414,14.585 6.586,17.413 24,34.827 41.414,17.413 38.586,14.585 		" d="M0.6 0.729L0.235 0.365L0.165 0.435L0.6 0.871L1.035 0.435L0.965 0.365Z" /></g></g></svg></span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                {/* <button className={css.scrollButton} onClick={shiftRight} disabled={currentIndex === requiredDataFormat.length - 1}>
                    &#8594;
                </button> */}
            </div>

            {isOpen !== null && (
                <div
                    ref={dropdownRef}
                    className={css.megadropDown}
                    style={{
                        position: 'fixed',
                        top: dropdownPosition.top, // Set dynamically calculated top
                        left: dropdownPosition.left, // Set dynamically calculated left
                    }}
                >
                <div className={css.megaDropDownInner}>
                {requiredDataFormat[isOpen].items.map((item, idx) => (
                        <div
                            key={idx}
                            className={css.items}
                            onClick={() => handleSelect(item.option, requiredDataFormat[isOpen].mainCategory?.key)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
                </div>
            )}
        </div>
    );
};

export default ServicesDropDown;
